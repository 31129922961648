<template>
  <c-box
    id="kuisioner"
    pos="relative"
    w="100%"
    mx="auto"
    :bg="['#F2F2F2', '#FFF']"
    border="1px solid #f2f2f2"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['0px', '16px']"
    :p="['0.5rem', '1.5rem']"
    :min-height="['calc(100vh - 62px)', '74vh']"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          {
            label: 'Dashboard',
            href: '/',
          },
          {
            label: 'Formulir Gizi',
            href: '/',
            isCurrent: true,
          },
        ]"
      />
    </Portal>
    <c-box
      :w="['100%', '600px']"
      mx="auto"
      bg="#FFF"
      :p="['1rem', '0px']"
    >
      <Steps
        v-chakra
        :current="3"
        :total="6"
      />

      <c-box
        w="100%"
        max-width="590px"
        mx="auto"
        :margin-top="['16px', '24px']"
      >
        <c-box text-align="center">
          <c-text
            color="primary.400"
            font-family="roboto"
            text-transform="uppercase"
            font-weight="500"
            :font-size="['16px', '18px']"
            margin-bottom="10px"
          >
            Formulir Gizi (3/6)
          </c-text>
          <c-heading
            font-weight="700"
            :font-size="['18px', '28px']"
            :line-height="['27px', '42px']"
            color="black.900"
          >
            Data Pola &amp; Riwayat Makan
          </c-heading>
        </c-box>
        <form @submit.prevent="submit">
          <c-box
            v-chakra="{
              'input[type=radio]:checked': {
                bg: 'primary.400',
                borderColor: 'primary.400',
              },
              'input[type=checkbox]:checked': {
                bg: 'primary.400',
                borderColor: 'primary.400',
              },
              div: {
                fontSize: ['14px', '18px'],
              },
            }"
            margin-top="24px"
          >
            <FormSelect
              v-model="foodPreference"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.foodPreference.$invalid"
              label="Preferensi Makanan"
              :options="FOOD_PREFERENCES"
            />

            <FormSelect
              v-model="mainFoodConsumption"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.mainFoodConsumption.$invalid"
              label="Biasanya, berapa kali kamu makan berat atau makan utama dalam 1 hari?"
              placeholder="Bagaimana kebiasaan kamu"
              :options="MAIN_FOOD_CONSUMPTION"
            />

            <FormSelect
              v-model="snackConsumption"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.snackConsumption.$invalid"
              label="Biasanya, berapa kali kamu makan cemilan dalam 1 hari?"
              placeholder="Jenis makanan"
              :options="SNACK_CONSUMPTION"
            />

            <FormInput
              v-model="foodSchedule"
              type="textarea"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.foodSchedule.$invalid"
              label="Jelaskan jadwal atau jam makanmu dalam 1 hari"
              placeholder="Contoh: sarapan jam 8 pagi, makan siang jam 1, makan malam jam 7, cemilan jam 10 pagi dan jam 4 sore"
            />

            <FormInput
              v-model="foodConsumptionType"
              type="textarea"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.foodConsumptionType.$invalid"
              label="Masakan atau menu apa yang hampir setiap hari kamu konsumsi?"
              placeholder="Contoh: nasi uduk, telur dadar, ayam geprek, sayur bayam"
            />

            <FormInput
              v-model="dislikeFood"
              type="textarea"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.dislikeFood.$invalid"
              label="Makanan apa yang tidak kamu sukai?"
              placeholder="Mohon dituliskan nama bahan makanannya agar kami dapat membuat saran menu yang sesuai dengan selera kamu"
            />

            <FormInput
              v-model="favoriteFood"
              type="textarea"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.favoriteFood.$invalid"
              label="Kamu sukanya cita rasa makanan atau jenis makanan yang seperti apa?"
              placeholder="Contoh: Western food, japanese food, makanan khas minang, makanan khas manado, makanan khas jawa"
            />

            <FormInput
              v-model="expectedBreakfast"
              type="textarea"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.expectedBreakfast.$invalid"
              label="Masakan atau menu apa yang biasa kamu konsumsi saat sarapan?"
              placeholder="Mohon dituliskan jenis makanan yang biasa kamu konsumsi atau yang kamu harapkan muncul di menu sarapan kamu."
            />

            <FormInput
              v-model="expectedLunchDinner"
              type="textarea"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.expectedLunchDinner.$invalid"
              label="Masakan atau menu apa yang biasa kamu konsumsi saat makan siang dan makan malam?"
              placeholder="Masukkan jenis makanan"
            />

            <FormInput
              v-model="foodAllergies"
              type="input"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.foodAllergies.$invalid"
              label="Adakah makanan yang menyebabkan kamu alergi, jika ada sebutkan jenis makannya?"
              placeholder="Mohon dituliskan jenis makanan yang biasa kamu konsumsi atau yang kamu harapkan muncul di menu sarapan kamu."
            />

            <FormInput
              v-model="fluidConsumption"
              type="number"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.fluidConsumption.$invalid"
              label="Berapa gelas rata-rata jumlah konsumsi air mineral (air putih) dalam satu hari?"
              placeholder="Contoh: 8 atau 6.5"
              right-element="Gelas"
            />

            <FormRadio
              v-model="vegetablesConsumption"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.vegetablesConsumption.$invalid"
              label="Berapa banyak kamu mengonsumsi sayuran dalam sehari?"
              :options="VEGETABLES_CONSUMPTION"
            />

            <FormRadio
              v-model="fruitConsumption"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.fruitConsumption.$invalid"
              label="Berapa banyak kamu mengonsumsi buah dalam sehari?"
              :options="FRUIT_CONSUMPTION"
            />

            <FormRadio
              v-model="beveragesComsumption"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.beveragesComsumption.$invalid"
              label="Berapa banyak kamu mengonsumsi minuman kemasan atau minuman manis dalam sehari?"
              :options="BEVERAGES_CONSUMPTION"
            />

            <FormRadio
              v-model="friedSnackConsumption"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.friedSnackConsumption.$invalid"
              label="Berapa banyak kamu mengonsumsi cemilan gorengan (diluar lauk dalam makan utama) dalam sehari?"
              :options="FRIED_SNACK_CONSUMPTION"
            />

            <FormRadio
              v-model="saltySnackConsumption"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.saltySnackConsumption.$invalid"
              label="Berapa sering kamu mengonsumsi makanan ringan asin atau gurih dalam sehari (seperti makanan ringan kemasan, keripik, kacang goreng asin)?"
              :options="SALTY_SNACK_CONSUMPTION"
            />

            <FormRadio
              v-model="sweetSnackConsumption"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.sweetSnackConsumption.$invalid"
              label="Berapa sering kamu mengonsumsi cemilan manis dalam sehari (seperti kue-kue manis, brownies, cake, biskuit, coklat, wafer)?"
              :options="SWEET_SNACK_CONSUMPTION"
            />

            <FormCheckbox
              v-model="personServeFood"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.personServeFood.$invalid"
              label="Siapakah yang menyediakan makanan bagi kamu?"
              :options="PERSON_SERVE_FOOD"
            />

            <FormSelect
              v-model="milkFoodAndDrinkConsumption"
              :is-disabled="!isEditable"
              is-required
              :is-invalid="$v.milkFoodAndDrinkConsumption.$invalid"
              label="Apakah kamu bisa dan terbiasa konsumsi makanan dan minuman yang mengandung susu?"
              placeholder="Pilih Jawaban"
              :options="MILK_FOOD_AND_DRINK_CONSUMPTION"
            />

            <FormInput
              v-model="lifestyle"
              type="textarea"
              :is-disabled="!isEditable"
              :is-required="false"
              :is-invalid="$v.lifestyle.$invalid"
              label="Tak kenal maka tak sayang.. Tolong ceritakan dong hal lain tentang pola makan dan gaya hidup kamu yang perlu kami tahu :)"
              placeholder="Masukkan cerita"
            />

            <c-grid
              margin-top="16px"
              template-columns="repeat(2, 1fr)"
              gap="3"
            >
              <c-button
                w="100%"
                background-color="white"
                color="primary.400"
                border-color="primary.400"
                border-radius="100px"
                variant="outline"
                px="30px"
                h="48px"
                :is-disabled="isSubmitting || isBack"
                @click="onPrevious"
              >
                Sebelumnya
              </c-button>
              <c-button
                w="100%"
                background-color="primary.400"
                color="white"
                border-radius="100px"
                variant="solid"
                px="30px"
                :is-disabled="isDisabled || isBack"
                :is-loading="isSubmitting"
                loading-text="Submitting"
                h="48px"
                type="submit"
              >
                Selanjutnya
              </c-button>
            </c-grid>
          </c-box>
        </form>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapMutations, mapState } from 'vuex'
import { required, decimal, minValue } from 'vuelidate/lib/validators'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import Steps from '@/components/steps'
import FormSelect from '@/views/client/kuisioner/forms/form-select'
import FormInput from '@/views/client/kuisioner/forms/form-input'
import FormRadio from '@/views/client/kuisioner/forms/form-radio'
import FormCheckbox from '@/views/client/kuisioner/forms/form-checkbox'
import {
  FOOD_PREFERENCES,
  MAIN_FOOD_CONSUMPTION,
  SNACK_CONSUMPTION,
  VEGETABLES_CONSUMPTION,
  FRUIT_CONSUMPTION,
  BEVERAGES_CONSUMPTION,
  FRIED_SNACK_CONSUMPTION,
  SALTY_SNACK_CONSUMPTION,
  SWEET_SNACK_CONSUMPTION,
  PERSON_SERVE_FOOD,
  MILK_FOOD_AND_DRINK_CONSUMPTION,
} from '@/constants/questionnaire'

export default {
  name: 'FormulirGizi3altPage',
  components: {
    BreadcrumbPath,
    Steps,
    FormSelect,
    FormInput,
    FormRadio,
    FormCheckbox,
  },
  data() {
    return {
      foodPreference: null,
      mainFoodConsumption: null,
      snackConsumption: null,
      foodSchedule: null,
      foodConsumptionType: null,
      dislikeFood: null,
      favoriteFood: null,
      expectedBreakfast: null,
      expectedLunchDinner: null,
      foodAllergies: null,
      fluidConsumption: null,
      vegetablesConsumption: null,
      fruitConsumption: null,
      beveragesComsumption: null,
      friedSnackConsumption: null,
      saltySnackConsumption: null,
      sweetSnackConsumption: null,
      personServeFood: [],
      milkFoodAndDrinkConsumption: null,
      lifestyle: null,
      isSubmitting: false,
      isBack: false,

      // -- Dinamis form
      questions: [],

      // options
      FOOD_PREFERENCES: FOOD_PREFERENCES,
      MAIN_FOOD_CONSUMPTION: MAIN_FOOD_CONSUMPTION,
      SNACK_CONSUMPTION: SNACK_CONSUMPTION,
      VEGETABLES_CONSUMPTION: VEGETABLES_CONSUMPTION,
      FRUIT_CONSUMPTION: FRUIT_CONSUMPTION,
      BEVERAGES_CONSUMPTION: BEVERAGES_CONSUMPTION,
      FRIED_SNACK_CONSUMPTION: FRIED_SNACK_CONSUMPTION,
      SALTY_SNACK_CONSUMPTION: SALTY_SNACK_CONSUMPTION,
      SWEET_SNACK_CONSUMPTION: SWEET_SNACK_CONSUMPTION,
      PERSON_SERVE_FOOD: PERSON_SERVE_FOOD,
      MILK_FOOD_AND_DRINK_CONSUMPTION: MILK_FOOD_AND_DRINK_CONSUMPTION,
    }
  },
  validations: {
    foodPreference: { required },
    mainFoodConsumption: { required },
    snackConsumption: { required },
    foodSchedule: { required },
    foodConsumptionType: { required },
    dislikeFood: { required },
    favoriteFood: { required },
    expectedBreakfast: { required },
    expectedLunchDinner: { required },
    foodAllergies: { required },
    fluidConsumption: { required, decimal, minValue: minValue(0) },
    vegetablesConsumption: { required },
    fruitConsumption: { required },
    beveragesComsumption: { required },
    friedSnackConsumption: { required },
    saltySnackConsumption: { required },
    sweetSnackConsumption: { required },
    personServeFood: { required },
    milkFoodAndDrinkConsumption: { required },
    lifestyle: {},
  },
  computed: {
    ...mapState({
      kuisData: (s) => s.kuisioner.dietAndEatingHistory,
      informedConsent: (s) => s.kuisioner.informedConsent,
      currentStep: (s) => s.kuisioner.currentStep,
    }),
    isEditable() {
      return this.$route.params.isEditable ?? true
    },
    isDisabled() {
      return this.$v.$invalid
    },
    value() {
      return {
        foodPreference: this.foodPreference,
        mainFoodConsumption: this.mainFoodConsumption,
        snackConsumption: this.snackConsumption,
        foodSchedule: this.foodSchedule ? this.foodSchedule?.split(',')?.map((it) => it.trim()) : null,
        foodConsumptionType: this.foodConsumptionType?.split(',')?.map((it) => it.trim()),
        dislikeFood: this.dislikeFood?.split(',')?.map((it) => it.trim()),
        favoriteFood: this.favoriteFood?.split(',')?.map((it) => it.trim()),
        expectedBreakfast: this.expectedBreakfast?.split(',')?.map((it) => it.trim()),
        expectedLunchDinner: this.expectedLunchDinner?.split(',')?.map((it) => it.trim()),
        foodAllergies: this.foodAllergies?.split(',')?.map((it) => it.trim()),
        fluidConsumption: this.fluidConsumption,
        vegetablesConsumption: this.vegetablesConsumption,
        fruitConsumption: this.fruitConsumption,
        beveragesComsumption: this.beveragesComsumption,
        friedSnackConsumption: this.friedSnackConsumption,
        saltySnackConsumption: this.saltySnackConsumption,
        sweetSnackConsumption: this.sweetSnackConsumption,
        personServeFood: this.personServeFood,
        milkFoodAndDrinkConsumption: this.milkFoodAndDrinkConsumption,
        lifestyle: this.lifestyle,
      }
    },
    answers() {
      return this.questions.map((it) => ({
        questionId: it.id,
        answer: it.answer,
        question: it.question,
      }))
    },
  },
  watch: {
    // programId: {
    //   immediate: true,
    //   async handler(programId) {
    //     if (programId == null) return

    //     await this.$store.dispatch('kuisioner/getV2ClientDraft', this.programId)
    //   },
    // },
    kuisData: {
      immediate: true,
      handler(val) {
        if (val == null) return

        this.foodPreference = val.foodPreference
        this.mainFoodConsumption = val.mainFoodConsumption
        this.snackConsumption = val.snackConsumption
        this.foodSchedule = val.foodSchedule ? val.foodSchedule.join(', ') : ''
        this.foodConsumptionType = val.foodConsumptionType ? val.foodConsumptionType.join(', ') : ''
        this.dislikeFood = val.dislikeFood ? val.dislikeFood.join(', ') : ''
        this.favoriteFood = val.favoriteFood ? val.favoriteFood.join(', ') : ''
        this.expectedBreakfast = val.expectedBreakfast ? val.expectedBreakfast.join(', ') : ''
        this.expectedLunchDinner = val.expectedLunchDinner ? val.expectedLunchDinner.join(', ') : ''
        this.foodAllergies = val.foodAllergies ? val.foodAllergies.join(', ') : ''
        this.fluidConsumption = val.fluidConsumption
        this.vegetablesConsumption = val.vegetablesConsumption
        this.fruitConsumption = val.fruitConsumption
        this.beveragesComsumption = val.beveragesComsumption
        this.friedSnackConsumption = val.friedSnackConsumption
        this.saltySnackConsumption = val.saltySnackConsumption
        this.sweetSnackConsumption = val.sweetSnackConsumption
        this.personServeFood = val.personServeFood ?? []
        this.milkFoodAndDrinkConsumption = val.milkFoodAndDrinkConsumption
        this.lifestyle = val.lifestyle
      },
    },
    $data: {
      handler: _.debounce(function() {
        if (!this.isSubmitting && !this.isBack) {
          this.setStoreDietAndEatingHistory(this.value)
        }
      }, 1000),
      deep: true,
    },
  },
  async created() {
    if (this.currentStep && this.currentStep != 3) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/${this.currentStep}`)
    } else if (this.currentStep == null) {
      this.$router.push(`/quizionary/${this.$route.params.programId}/statement`)
    } else if (!this.informedConsent?.isAgreeInformedConsent) {
      const res = await this.reqStoreAnswerQuestionnaireDraft({
        programId: this.programId,
        filter: 'informedConsent',
      })
      if (!res?.informedConsent?.isAgreeInformedConsent) {
        await this.$store.dispatch('kuisioner/updateCurrentStep', 0)
        this.$router.replace(`/quizionary/${this.$route.params.programId}/statement`)
      }
    }
  },
  mounted() {
    this.reqStoreAnswerQuestionnaireDraft({
      programId: this.programId,
      filter: 'dietAndEatingHistory',
    })
  },
  methods: {
    ...mapMutations({
      setStoreDietAndEatingHistory: 'kuisioner/setDietAndEatingHistory',
    }),
    ...mapActions({
      reqStoreAnswerQuestionnaireDraft: 'kuisioner/reqStoreAnswerQuestionnaireDraft',
    }),
    async submit() {
      try {
        if (this.isEditable) {
          this.$v.$touch()
          if (this.$v.$invalid) return

          this.isSubmitting = true
          await this.$store.dispatch('kuisioner/setDietAndEatingHistory', {
            programId: this.$route.params.programId,
            value: this.value,
            answers: this.answers,
            step: 4,
          })
        }

        await this.$store.dispatch('kuisioner/updateCurrentStep', 4)
        await this.$store.commit('kuisioner/resetDietAndEatingHistory')
        this.$router.push({
          name: 'client.kuisioner4',
          params: this.$route.params,
        })
      } catch (e) {
        this.$errorToast({
          message: e.response?.data?.message ?? e.toString(),
        })
        if (e.response?.data?.message === 'Choose Nutritionist First') {
          this.$router.push({
            name: 'client.nutritionists',
          })
        }
      } finally {
        this.isSubmitting = false
      }
    },
    async onPrevious() {
      this.isBack = true
      await this.$store.dispatch('kuisioner/updateCurrentStep', 2)
      await this.$store.commit('kuisioner/resetDietAndEatingHistory')
      this.$router.push({
        name: 'client.kuisioner2',
        params: this.$route.params,
      })
    },
  },
}
</script>

<style scoped>
/* ::v-deep
  [data-chakra-component="CRadio"]
  [data-chakra-component="CControlBox"] {
  margin-top: 5px;
} */
</style>
